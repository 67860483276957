import './App.css';


function App() {
  return (
    
    
      <div></div>

      /*
      <div className="App h-screen w-screen flex justify-center items-center">
      <div className='flex flex-row items-center'>
        <div className='flex flex-col items-center'>
          <Penguins className="w-16 h-auto text-gold" />
        </div>
        <div className='pl-8'>
          {
            //<Logo className="mt-2 w-32 h-auto text-white" />
            //<p className='mt-4 text-beige font-nunito font-bold text-sm'>Hier entsteht unsere Webseite</p>
            //<p className='mt-4 text-beige font-nunito text-sm'>roberto catto – roberto@zeitbegleiter.ch</p>
          }


        </div>
      </div>
    </div>
    */
    
    
  );
}

export default App;
